import BSN from "bootstrap.native";
import * as XLSX from 'xlsx-js-style';
window.XLSX = XLSX;
import moment from "moment";
import {
  buildExportUrl,
  exportTableXlsx
} from "../../../helpers/tabulator_export_helper";
import {ServicesController} from "../services_controller";

export default class extends ServicesController {
  static targets = [
    "from_city_ids",
    "to_city_ids",
    "service_types",
    "distance_from",
    "distance_to",
    "status",
    // tables
    "table",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    if (this.hasTableTarget) {
      this._createServicesTable().then(() => {
        console.log('Services table table was successfully created.');
      }).catch(error => {
        console.error('Services Table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  // table filters action
  filterTable(event) {
    let filters = this._getPageFilters();

    // filter tables if they are active
    this._filterTable(filters).then(() => {
      console.log('Services table was successfully filtered.');
    }).catch(error => {
      console.error('Services table filtered Error:', error);
    });

    this._filtersToHistoryUrl(filters);
  }

  // table export action
  exportTable(event) {
    event.stopPropagation();

    this._exportTableXlsx();
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterTable(filters) {
    let tableTabulator = this.tableTarget.tabulator;
    if (!this.hasTableTarget || !tableTabulator) return;

    this._setFilterToTable(tableTabulator, filters).then(() => {
      console.log('Services table was successfully filtered.');
    }).catch(error => {
      console.error('Services table filtered Error:', error);
    });
  }

  //
  async _createServicesTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("table-load-url");
    const lang = document.body.dataset.lang;
    let persistenceID = "transport_services_table_" + lang;

    await this._createTable(
      url,
      this.tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  _getPageFilters() {
    let filters = []

    if (this.hasFrom_city_idsTarget) {
      filters.push({
        field: "from_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.from_city_idsTarget)
      })
    }

    if (this.hasTo_city_idsTarget) {
      filters.push({
        field: "to_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.to_city_idsTarget)
      })
    }

    if (this.hasService_typesTarget) {
      filters.push({
        field: "service_types",
        type: "=",
        value: super._getSelectCollectionValues(this.service_typesTarget)
      })
    }

    if (this.hasDistance_fromTarget) {
      filters.push({
        field: "distance_from",
        type: "=",
        value: this.distance_fromTarget.value
      })
    }

    if (this.hasDistance_toTarget) {
      filters.push({
        field: "distance_to",
        type: "=",
        value: this.distance_toTarget.value
      })
    }

    if (this.hasStatusTarget) {
      filters.push({
        field: "statuses",
        type: "=",
        value: super._getSelectCollectionValues(this.statusTarget)
      })
    }
    return filters;
  }

  // export
  // Export from tabulator
  _exportTableXlsx() {
    let tableTabulator = this._tableTabulator;
    let url = buildExportUrl(tableTabulator);
    let title = "Services - " + moment().format("YYYY-MM-DD_HH-mm-ss");

    exportTableXlsx(url, tableTabulator, title);
  }
}