import Flickity from "flickity";
import Tabulator from "tabulator-tables";
import { VehiclesController } from "../vehicles_controller"

require("flickity-fullscreen");

export default class extends VehiclesController {
  static targets = [
    "sidebar_vehicle_category_ids", "sidebar_vehicle_make_ids", "sidebar_issue_years",
    "sidebar_transmission_types", "sidebar_states", "sidebar_in_operation_date_filter",
    "sidebar_in_operation_date", "sidebar_out_of_operation_date_filter",
    "sidebar_out_of_operation_date", "sidebar_archive", "sidebar_sixt",  "sidebar_current_filter",
    "sidebar_filter",
    // table
    "table",
    // content
    "gallery_container",
    // sidebar_sort
    "sidebar_sort", "sidebar_current_sort"
  ]

  connect() {
    // Sidebar
    if (this.hasTableTarget) {
      this._createSidebarTable()
        .then(() => {
          console.log('Sidebar table was successfully created.');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    // Gallery
    if (this.hasGallery_containerTarget) {
      this._createGallery();
    }
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let table = this.tableTarget.tabulator;

    // change sort dir
    if (sort_dir === "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this._getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // Sidebar Filter
  setSidebarFilter() {
    this._setSidebarFilterToTable();
  }

  //
  // Private
  //

  _setSidebarFilterToTable() {
    let filters = this._getSidebarPageFilters();
    if (filters.length) {
      let table = this.tableTarget.tabulator;
      table.setFilter(filters);
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = super._convertToUrlParams(filters);
      if (filters_url.toString())
        history_url += "?" + filters_url;

      super.historyPush(history_url);
    }
  }

  // gen icon html
  _getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir === "asc") up_or_down = "down"
    return column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>";
  }

  // Create Sidebar table
  async _createSidebarTable() {
    const table_target = this.tableTarget;
    let table_props = this._buildTableProps;
    //
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  get _buildTableProps() {
    const columns = this.data.get("table-columns");
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    //
    const url = this.data.get("table-load-url");
    //
    let formatRow = super._defaultFormatRow
    let rowClick = super._defaultRowClick
    //
    let props = {
      columns: JSON.parse(columns),
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: this._getSidebarPageFilters(),
      //
      rowFormatter: function (row) { formatRow(row) },
      //
      rowClick: function (e, row) { rowClick(e, row) }
    }
    //
    return Object.assign(super._getDefaultSidebarTableProps(url), props);
  }

  // Sidebar filters
  _getSidebarPageFilters() {
    let filters = []
    if (this.hasSidebar_vehicle_category_idsTarget) {
      filters.push({
        field: "vehicle_category_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_vehicle_category_idsTarget)
      })
    }

    if (this.hasSidebar_vehicle_make_idsTarget) {
      filters.push({
        field: "vehicle_make_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_vehicle_make_idsTarget)
      })
    }

    if (this.hasSidebar_issue_yearsTarget) {
      filters.push({
        field: "issue_years",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_issue_yearsTarget)
      })
    }

    if (this.hasSidebar_transmission_typesTarget) {
      filters.push({
        field: "transmission_types",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_transmission_typesTarget)
      })
    }

    if (this.hasSidebar_statesTarget) {
      filters.push({
        field: "states",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_statesTarget)
      })
    }

    if (this.hasSidebar_in_operation_date_filterTarget) {
      filters.push({
        field: "in_operation_date_filter",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_in_operation_date_filterTarget)
      })
    }

    if (this.hasSidebar_in_operation_dateTarget) {
      filters.push({
        field: "in_operation_date",
        type: "=",
        value: this.sidebar_in_operation_dateTarget.value
      })
    }

    if (this.hasSidebar_out_of_operation_date_filterTarget) {
      filters.push({
        field: "out_of_operation_date_filter",
        type: "=",
        value: super._getSelectCollectionValues(this.sidebar_out_of_operation_date_filterTarget)
      })
    }

    if (this.hasSidebar_out_of_operation_dateTarget) {
      filters.push({
        field: "out_of_operation_date",
        type: "=",
        value: this.sidebar_out_of_operation_dateTarget.value
      })
    }

    if (this.hasSidebar_archiveTarget) {
      if (this.sidebar_archiveTarget.checked) {
        filters.push({
          field: "archive",
          type: "=",
          value: this.sidebar_archiveTarget.checked
        })
      }
    }

    if (this.hasSidebar_sixtTarget) {
      if (this.sidebar_sixtTarget.checked) {
        filters.push({
          field: "sixt",
          type: "=",
          value: this.sidebar_sixtTarget.checked
        })
      }
    }
    return filters;
  }

  // Create Gallery
  _createGallery() {
    const gallery_container = this.gallery_containerTarget;
    new Flickity(gallery_container, {
      lazyLoad: 1,
      fullscreen: true,
      wrapAround: true
    });
  }
}