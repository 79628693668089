import BSN from "bootstrap.native";
import { VehiclesController } from "../vehicles_controller";

export default class extends VehiclesController {
  static targets = [
    "vehicle_category_ids",
    "vehicle_make_ids",
    "issue_years",
    "transmission_types",
    "states",
    "in_operation_date_filter",
    "in_operation_date",
    "out_of_operation_date_filter",
    "out_of_operation_date",
    "archive",
    "sixt",
    // tables
    "in_operation_table", "out_of_operation_table", "out_of_operation_tab", "in_operation_tab",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    let isInOperationTabActive = this.in_operation_tabTarget.classList.contains('active');
    if (this.hasIn_operation_tableTarget && isInOperationTabActive) {
      this._crateInOperationTable().then(() => {
        console.log('In_operation_table table was successfully created.');
      }).catch(error => {
        console.error('In_operation_table Error:', error);
      });
    }

    let isOutOfOperationTabActive = this.out_of_operation_tabTarget.classList.contains('active');
    if (this.hasOut_of_operation_tabTarget && isOutOfOperationTabActive) {
      this._createOutOfOperationTable().then(() => {
        console.log('Out_of_operation_table table was successfully created.');
      }).catch(error => {
        console.error('Out_of_operation_table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  loadInOperationTable(event) {
    event.preventDefault();

    let inOperationTabulator = this.in_operation_tableTarget.tabulator;

    // if table exist then filter it if filters changed
    if (inOperationTabulator) {
      let filterChanged = super._isFiltersChangedForTable(this.in_operation_tableTarget);
      // filter table if filters changed
      if (filterChanged) {
        let filters = this._getPageFilters();
        this._filterInOperationTable(filters).then(() => {
          console.log('In operation filterTable');
        }).catch(error => {
          console.error('In operation filterTable Error:', error);
        });
      }
    } else {
      // if table not exist then create it
      this._crateInOperationTable().then(() => {
        console.log('In_operation_table table was successfully created.');
      }).catch(error => {
        console.error('In_operation_table Error:', error);
      });
    }
  }

  loadOutOfOperationTable(event) {
    event.preventDefault();

    let outOfOperationTabulator = this.out_of_operation_tableTarget.tabulator;
    if (outOfOperationTabulator) {
      let filterChanged = super._isFiltersChangedForTable(this.out_of_operation_tableTarget);
      // filter table if filters changed
      if (filterChanged) {
        let filters = this._getPageFilters();
        this._filterOutOfOperationTable(filters).then(() => {
          console.log('Out_of_operation_table filterTable');
        }).catch(error => {
          console.error('Out_of_operation_table filterTable Error:', error);
        });
      }
    } else {
      this._createOutOfOperationTable().then(() => {
        console.log('Out_of_operation_table table was successfully created.');
      }).catch(error => {
        console.error('Out_of_operation_table Error:', error);
      });
    }
  }

  // table filters action
  filterTable(event) {
    let filters = this._getPageFilters();

    // Change dataset filtersChanged to true
    super._filtersChangedForTable(this.in_operation_tableTarget);
    super._filtersChangedForTable(this.out_of_operation_tableTarget);

    // filter tables if they are active
    this._filterInOperationTable(filters).then(() => {
      console.log('In operation filterTable');
    }).catch(error => {
      console.error('In operation filterTable Error:', error);
    });

    this._filterOutOfOperationTable(filters).then(() => {
      console.log('Out_of_operation_table filterTable');
    }).catch(error => {
      console.error('Out_of_operation_table filterTable Error:', error);
    });

    this._filtersToHistoryUrl(filters);
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterInOperationTable(filters) {
    let isInOperationTabActive = this.in_operation_tabTarget.classList.contains('active');
    let inOperationTabulator = this.in_operation_tableTarget.tabulator;
    if (!this.hasIn_operation_tableTarget || !inOperationTabulator || !isInOperationTabActive) return;

    this._setFilterToTable(inOperationTabulator, filters).then(() => {
      super._filtersAppliedForTable(this.in_operation_tableTarget);
      console.log('In operation table was successfully filtered.');
    }).catch(error => {
      console.error('In operation table filtered Error:', error);
    });
  }

  async _filterOutOfOperationTable(filters) {
    let isOutOfOperationTabActive = this.out_of_operation_tabTarget.classList.contains('active');
    let outOfOperationTabulator = this.out_of_operation_tableTarget.tabulator;
    if (!this.hasOut_of_operation_tableTarget || !outOfOperationTabulator || !isOutOfOperationTabActive) return;

    this._setFilterToTable(outOfOperationTabulator, filters).then(() => {
      super._filtersAppliedForTable(this.out_of_operation_tableTarget);
      console.log('Out_of_operation_table table was successfully filtered.');
    }).catch(error => {
      console.error('Out_of_operation_table table filtered Error:', error);
    });
  }

  //
  async _crateInOperationTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("in-operation-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_vehicle_in_operation_table_" + lang;

    await this._createTable(
      url,
      this.in_operation_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  async _createOutOfOperationTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("out-of-operation-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_vehicle_out_of_operation_table_" + lang;

    await this._createTable(
      url,
      this.out_of_operation_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  _getPageFilters() {
    let filters = []

    if (this.hasVehicle_category_idsTarget) {
      filters.push({
        field: "vehicle_category_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.vehicle_category_idsTarget)
      })
    }

    if (this.hasVehicle_make_idsTarget) {
      filters.push({
        field: "vehicle_make_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.vehicle_make_idsTarget)
      })
    }

    if (this.hasIssue_yearsTarget) {
      filters.push({
        field: "issue_years",
        type: "=",
        value: super._getSelectCollectionValues(this.issue_yearsTarget)
      })
    }

    if (this.hasTransmission_typesTarget) {
      filters.push({
        field: "transmission_types",
        type: "=",
        value: super._getSelectCollectionValues(this.transmission_typesTarget)
      })
    }

    if (this.hasStatesTarget) {
      filters.push({
        field: "states",
        type: "=",
        value: super._getSelectCollectionValues(this.statesTarget)
      })
    }

    if (this.hasIn_operation_date_filterTarget) {
      filters.push({
        field: "in_operation_date_filter",
        type: "=",
        value: super._getSelectCollectionValues(this.in_operation_date_filterTarget)
      })
    }

    if (this.hasIn_operation_dateTarget) {
      filters.push({
        field: "in_operation_date",
        type: "=",
        value: this.in_operation_dateTarget.value
      })
    }

    if (this.hasOut_of_operation_date_filterTarget) {
      filters.push({
        field: "out_of_operation_date_filter",
        type: "=",
        value: super._getSelectCollectionValues(this.out_of_operation_date_filterTarget)
      })
    }

    if (this.hasOut_of_operation_dateTarget) {
      filters.push({
        field: "out_of_operation_date",
        type: "=",
        value: this.out_of_operation_dateTarget.value
      })
    }

    if (this.hasArchiveTarget) {
      if (this.archiveTarget.checked) {
        filters.push({
          field: "archive",
          type: "=",
          value: this.archiveTarget.checked
        })
      }
    }

    if (this.hasSixtTarget) {
      if (this.sixtTarget.checked) {
        filters.push({
          field: "sixt",
          type: "=",
          value: this.sixtTarget.checked
        })
      }
    }

    return filters;
  }
}