import {ServicesController} from "../services_controller";

export default class extends ServicesController {
  static targets = ["delete_link"]

  destroyService(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._deleteService(url);
  }

  //
  // Private
  //
  _deleteService(url) {
    let confirmMessage = this.data.get("confirm-text");
    if (confirm(confirmMessage)) {
      Rails.ajax({
        url: url,
        type: 'delete',
        success: (data) => {
          if (data) {
            super._successNotify(data.notification_title, data.notification_message);
            this._removeRowFromTable(data.id);
          }
        },
        error: (data) => {
          if (data) {
            super._errorNotify(data.notification_title, data.notification_message);
          }
        }
      })
    }
  }

  _removeRowFromTable(id) {
    super._deleteTabulatorRowById(this._tableTabulator, id);
  }
}