import {ServicesController} from "../services_controller";

export default class extends ServicesController {
  static targets = [
    'is_manual',
    "from_city",
    "to_city",
    "from_city_id",
    "to_city_id"
  ]

  toggleManualFields(event) {
    event.preventDefault();
    this._manualToggleFields(event.currentTarget.checked);
  }

  newService(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);
      super._tableUpdateOrAddData(data.row_data);
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      window.layout_modal_md.setContent(data.form_content)
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.value;
    const currency_field = document.getElementById("transport_service_price_currency");
    console.log(currency_field);
    currency_field.innerHTML = currency;
  }
}