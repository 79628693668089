import { TransportMainController } from "../transport_main_controller";

export class ServicesController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalMD(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _tableUpdateOrAddData(row_data) {
    let table = this._tableTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _manualToggleFields(enable) {
    let from_city = this.from_cityTarget;
    let to_city = this.to_cityTarget;
    let from_city_id = this.from_city_idTarget;
    let to_city_id = this.to_city_idTarget;

    console.log(enable);

    if (enable) {
      from_city_id.choices.disable();
      to_city_id.choices.disable();
      from_city.removeAttribute('disabled');
      to_city.removeAttribute('disabled');
    } else {
      from_city_id.choices.enable();
      to_city_id.choices.enable();
      from_city.setAttribute('disabled', 'disabled');
      to_city.setAttribute('disabled', 'disabled');
    }

  }

  //
  // Private
  //
  get _tableTabulator() {
    let table_target = document.getElementById("transport--services-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }
}