import { TransportMainController } from "../transport_main_controller";

export class TariffsController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalMD(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }
}