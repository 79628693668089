import { TransportMainController } from "../transport_main_controller";

export class VehiclesController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalXL(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _sidebarTableUpdateOrAddData(row_data) {
    let table = this._sidebarTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _inOperationTableUpdateOrAddData(row_data) {
    let table = this._inOperationTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _outOfOperationTableUpdateOrAddData(row_data) {
    let table = this._outOfOperationTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _updateOrAddDataToTable(table_type, data) {
    console.log(table_type);
    switch (table_type) {
      case "sidebar":
        this._sidebarTableUpdateOrAddData(data.sidebar_row_data);
        break;
      case "in_operation":
        this._inOperationTableUpdateOrAddData(data.row_data);
        break;
      case "out_of_operation":
        this._outOfOperationTableUpdateOrAddData(data.row_data);
        break;
    }
  }

  //
  // Private
  //
  get _sidebarTabulator() {
    let table_target = document.getElementById("transport--vehicles-sidebar-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _inOperationTabulator() {
    let table_target = document.getElementById("transport--vehicles-in-operation-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _outOfOperationTabulator() {
    let table_target = document.getElementById("transport--vehicles-out-of-operation-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }
}